import './App.css';
import mixpanel from 'mixpanel-browser';

function App() {
  const handleLinkClick = () => {
    if (mixpanel && mixpanel.track) {
      mixpanel.track('Project Click', {"Project Name": "Pawfinder"});
    } else {
      console.error('MixPanel is not initialized');
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>UNDER CONSTRUCTION</h1>
        <a
          className="App-link"
          href="https://pawfinderapp.com"
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleLinkClick}
        >
          Check out my most recent project
        </a>
      </header>
    </div>
  );
}

export default App;
