import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import mixpanel from 'mixpanel-browser';

const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN;
console.log('MixPanel Token:', mixpanelToken);

if (mixpanelToken) {
  mixpanel.init(mixpanelToken, {debug: true, 'ignore_dnt': true});
} else {
  console.error('MixPanel token is not defined');
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Track page visit event
if (mixpanelToken) {
  mixpanel.track('Page View', {"Page Name": "Home Page"});
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
